import * as React from "react";
import { Helmet } from 'react-helmet';
import '../assets/css/pages/apps-page.css';
import AppsItem from "../components/AppsItem";
import Footer from "../components/Footer";
import Header, { CurrentPageType } from "../components/Header";
import AppsData from '../data/appsData.json';
import ReactGA from 'react-ga';

const AppsPage = () => {
  const renderApps = () => {
    const appsItems = AppsData.apps.map(({id, name, description, hasReadMore, iosLink, androidLink, iconName }) => {
      return (
        <AppsItem key={id} id={id} name={name} description={description} hasReadMore={hasReadMore} iosLink={iosLink} androidLink={androidLink} iconName={iconName} />
      );
    });
    return(
      <React.Fragment>
        {appsItems}
      </React.Fragment>
    )
  }

  React.useEffect(() => {
  	ReactGA.initialize('UA-88504612-1');
  	ReactGA.pageview("/apps");
  }, []);
  
  return (
    <React.Fragment>
      <Helmet>
        <title>Pixel Works Software: Apps</title>
      </Helmet>
      <div className="gradient"></div>
      <Header currentPage={CurrentPageType.APPS} />
      <main>
        {renderApps()}
      </main>
      <Footer />
    </React.Fragment>
  );
}

export default AppsPage;
