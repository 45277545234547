import React, { useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import '../assets/css/components/header.css';
import ImgPWSLogo from '../assets/images/pws-logo.svg';
import HeaderAnimation from './HeaderAnimation';

export enum CurrentPageType {
  APPS = 'APPS_PAGE',
  NEWS = 'NEWS_PAGE',
  CONTACT = 'CONTACT_PAGE',
  OTHER = 'OTHER_PAGE'
}

export interface HeaderProps {
  currentPage: CurrentPageType;
}

const Header: React.FC<HeaderProps> = ( {currentPage} ) => {
  const headerRef = useRef(null);

  return (
    <header ref={headerRef} className='header'>
      <HeaderAnimation headerRef={headerRef} />
      <img className='header__pws-logo' src={ImgPWSLogo} alt="Pixel Works Software Logo" width="130" height="33"/>
      <div className='header__navbar'>
        <Link className={`header__navbar__link ${currentPage === CurrentPageType.APPS && 'header__navbar__link--current'}`} to='/apps'>APPS</Link>
        <Link className={`header__navbar__link ${currentPage === CurrentPageType.NEWS && 'header__navbar__link--current'}`} to='/news'>NEWS</Link>
        <Link className={`header__navbar__link ${currentPage === CurrentPageType.CONTACT && 'header__navbar__link--current'}`} to='/contact'>CONTACT</Link>
      </div>
    </header>
  );
}

export default Header;