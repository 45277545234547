import React from 'react';
import { Link } from 'gatsby';
import '../assets/css/components/footer.css';

const Footer: React.FC = () => {
    return (
      <footer>
        <div className="footer__container">
          <p className="copyright">© 2022 Pixel Works Software</p>
          <div className="footer__navbar">
            <Link className="footer__navbar__link" to="/apps">APPS</Link>
            <Link className="footer__navbar__link" to="/news">NEWS</Link>
            <Link className="footer__navbar__link" to="/contact">CONTACT</Link>
          </div>
        </div>
    </footer>
    );
}

export default Footer;




