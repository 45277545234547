import React, { useEffect, useRef } from 'react';

export interface HeaderAnimationProps {
  headerRef: React.RefObject<HTMLDivElement>;
}

const HeaderAnimation: React.FC<HeaderAnimationProps> = ({ headerRef }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const draw = (ctx: CanvasRenderingContext2D, w: number, h: number, frameCount: number) => {
    const animParam1 = frameCount * 0.02;
    const animParam2 = frameCount * 0.03;
    const animParam3 = frameCount * 0.028;
    const animParam4 = frameCount * 0.018;

    ctx.clearRect(0, 0, w, h);

		ctx.beginPath();
		ctx.moveTo(0.2 * w, 0);
		ctx.bezierCurveTo(0.2 * w + 10 * Math.sin(animParam1), h * 1.2 + 10 * Math.sin(animParam2), 0.8 * w + 10 * Math.sin(animParam3), h * 1.2 + 10 * Math.sin(animParam4), 0.8 * w, 0);
		ctx.lineTo(0.3 * w, 0);

		ctx.fillStyle = "#FFF";
		ctx.fill();
  }

  useEffect(() => {
    const canvas = canvasRef.current;

    if (canvas === null) return;

    const ctx = canvas.getContext('2d');

    if (ctx === null) return;

    let frameCount = 0;
    let animationFrameId: number;

    const render = () => {
      frameCount++;
      const w = canvas.width;
      const h = canvas.height;
      draw(ctx, w, h, frameCount);
      animationFrameId = window.requestAnimationFrame(render);
    }
    render();

    return () => {
      window.cancelAnimationFrame(animationFrameId);
    }
  }, [draw]);

  useEffect(() => {
    const handleResize = () => {
      const canvas = canvasRef.current;

      if (canvas === null) return;

      const header = headerRef.current;

      if (header === null) return;
      
      canvas.width = header.offsetWidth;
    }

    window.addEventListener('resize', handleResize);
  },[]);

  return (
    <canvas ref={canvasRef} className='header-animation' width="1000" height="100"></canvas>
  );
}

export default HeaderAnimation;